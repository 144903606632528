import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Logo from './../assets/logo.png';
import './../styling/footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <Container fluid>
        <Row className="d-flex align-items-start">
          <Col lg={1} xs={2} md={1}></Col>

          <Col
            className="d-flex align-items-center flex-column d-flex justify-content-center  mt-3 mb-3 "
            lg={2}
            md={2}
          >
            <div className="links ">
              <h5>
                <span>Navigation</span>
              </h5>

              <ul>
                <li>
                  <a href="/">Investment</a>
                </li>
                <li>
                  <a href="/">Our Team</a>
                </li>
                <li>
                  <a href="/career">Careers</a>
                </li>
              </ul>
            </div>
          </Col>

          <Col
            lg={2}
            md={2}
            className="d-flex align-items-center flex-column d-flex justify-content-center  mt-3 mb-3 "
          >
            <div className="links">
              <h5>
                <span>Our Clients</span>
              </h5>
              <ul>
                <li>
                  <a href="/login">Investor Login</a>
                </li>
              </ul>
            </div>
          </Col>

          <Col
            lg={2}
            md={2}
            className="d-flex align-items-center flex-column d-flex justify-content-center  mt-3 mb-3 "
          >
            <div className="links">
              <h5>
                <span>Contact</span>
              </h5>
              <ul>
                <li>
                  <a href="mailto:steve@laplaceinvestment.com">
                    <div className="email-container">
                      <a href="mailto:steve@laplaceinvestment.com">
                        steve@laplaceinvestment.com
                      </a>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="tel:415-530-6674">Phone:415-530-6674</a>
                </li>
              </ul>
            </div>
          </Col>

          <Col lg={2}></Col>
          <Col
            className="d-flex align-items-center flex-column d-flex justify-content-center  mt-3 mb-3 "
            lg={2}
            md={2}
          >
            <Image src={Logo} alt="Logo" className=" mt-3 mb-3" />
          </Col>
          <Col lg={1}></Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
