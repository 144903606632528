import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import config from './config';
import './../styling/ContactUs.css';

const ContactUs = () => {
  const publicKey = config.publicKey;
  const serviceId = config.serviceId;
  const templateID = config.templateID;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .send(
        serviceId,
        templateID,
        {
          name: name,
          email: email,
          message: message,
        },
        publicKey
      )
      .then((response) => {
        console.log('Email sent:', response);
        setEmailSent(true);
        setFormSubmitted(true);
      })
      .catch((error) => {
        console.error('Email error:', error);
      });
  };

  return (
    <div className="contact">
      <div className="up">
        <div className="content">
          <h1>Contact Us</h1>
          <p style={{ color: 'white', fontSize: '1.5rem' }}>
            {' '}
            To learn more about Laplace, please feel free to reach us.
          </p>
        </div>

        <div className="contact-container">
          <div className="left-column">
            {formSubmitted ? (
              <div className="confirmation-message">
                Your message has been successfully sent, and we will respond as
                soon as possible.
              </div>
            ) : (
              <div className="left">
                <form id="contact-form" onSubmit={sendEmail}>
                  <label htmlFor="name">Your Name*</label>
                  <input
                    type="text"
                    id="name"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <br />
                  <label htmlFor="email">Your Email*</label>
                  <input
                    type="email"
                    id="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <br />
                  <label htmlFor="message">Your Message*</label>
                  <input
                    type="textarea"
                    id="message"
                    required
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <br />
                  <button type="submit">Send</button>
                </form>
              </div>
            )}
          </div>

          <div className="right-column">
            <div className="left">
              <p>
                Email: <br />
                HiLaplace@gmail.com
              </p>
              <hr />
              <p>
                Phone: <br />
                216-866-0878
              </p>
              <hr />
              <p>Others</p>
              <ul>
                <li>
                  <a href="https://www.linkedin.com/company/laplace-investment-management-inc">
                    LinkedIn Profile
                  </a>
                </li>
                <li>
                  <a href="#">Resume</a>
                </li>
                <li>
                  <a href="#">Website</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="buttom"></div>
    </div>
  );
};

export default ContactUs;
