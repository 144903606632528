import React, { useState, useEffect } from 'react';
import PdfViewer from './PdfViewer';
import './../styling/PdfFileList.css';
import { Col, Row } from 'react-bootstrap';

const PdfFileList = () => {
  const [selectedYear, setSelectedYear] = useState('2023');
  const [selectedMonth, setSelectedMonth] = useState('02');
  const [selectedFile, setSelectedFile] = useState(null);
  const files = [
    '20230201-03.pdf',
    '20230206-11.pdf',
    '20230206-11-2.pdf',
    '20230213-17.pdf',
    '20230221-24.pdf',
    '20230227-0303.pdf',
    '20230306-0310.pdf',
    '20230313-17.pdf',
    '20230320-24.pdf',
    '20230327-31.pdf',
    '20230403-07.pdf',
    '20230410-14.pdf',
    '20230417-21.pdf',
    '20230424-28.pdf',
    '20230501-05.pdf',
    '20230508-12.pdf',
    '20230515-19.pdf',
    '20230522-26.pdf',
    '20230530-0603.pdf',
    '20230605-0609.pdf',
    '20230612-0616.pdf',
    '20230620-0623.pdf',
    '20230626-0630.pdf',
    '20230703-0707.pdf',
    '20230710-0714.pdf',
    '20230717-0721.pdf',
    '20230724-0728.pdf',
    '20230731-0803.pdf',
    '20230807-0811.pdf',
    '20230814-0915.pdf',
    '20231120-1124.pdf',
    '20231127-1201.pdf',
    '20231204-1208.pdf',
  ];

  useEffect(() => {
    const initialFiles = getFilesForMonth(selectedYear, selectedMonth);
    initialFiles.sort((a, b) => {
      const startDateA = a.substring(0, 8);
      const startDateB = b.substring(0, 8);

      return startDateB.localeCompare(startDateA);
    });

    if (initialFiles.length > 0) {
      setSelectedFile(initialFiles[0]);
    }
  }, [selectedYear, selectedMonth]);

  const getFilesForMonth = (year, month) => {
    const yearMonth = `${year}${month}`;
    return files.filter((file) => file.startsWith(yearMonth));
  };

  const filteredFiles = getFilesForMonth(selectedYear, selectedMonth);

  return (
    <Row>
      {/* <Col style={{ display: 'flex', fontFamily: 'Arial, sans-serif' }}> */}
      <Col md={4} style={{flex: 1, padding: '10px'}}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%'
        }}>
          <select
              onChange={(e) => setSelectedYear(e.target.value)}
              value={selectedYear}
              style={{
                padding: '5px',
                backgroundColor: 'lightgrey',
                borderRadius: '5px',
                minWidth: '120px',
                margin: '0 10px'
              }}
          >
            <option value="2022">2022</option>
            <option value="2023">2023</option>
          </select>
          <select
              onChange={(e) => setSelectedMonth(e.target.value)}
              value={selectedMonth}
              style={{
                padding: '5px',
                backgroundColor: 'lightgrey',
                borderRadius: '5px',
                minWidth: '120px',
                margin: '0 10px'
              }}
          >
            <option value="01">January</option>
            <option value="02">February</option>
            <option value="03">March</option>
            <option value="04">April</option>
            <option value="05">May</option>
            <option value="06">June</option>
            <option value="07">July</option>
            <option value="08">August</option>
            <option value="09">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
        </div>
        <div style={{ margin: '20px' }}>
          {filteredFiles
              .sort((a, b) => {
                const startDateA = a.substring(0, 8);
                const startDateB = b.substring(0, 8);

                return startDateB.localeCompare(startDateA);
              })
              .map((file) => {
                const startDate = file.substring(0, 8);
                const formattedStartDate = `${startDate.substring(
                    4,
                    6
                )}/${startDate.substring(6, 8)}/${startDate.substring(0, 4)}`;

                return (
                    <div
                        key={file}
                        style={{marginBottom: '10px', cursor: 'pointer'}}
                    >
                      <div
                          style={{
                            height: '60px',
                            backgroundColor: '#c991fb',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'white',
                            borderRadius: '5px',
                            transition: 'background-color 0.3s',
                          }}
                          onClick={() => setSelectedFile(file)}
                          onMouseEnter={(e) =>
                              (e.target.style.backgroundColor = '#c991fb')
                          }
                          onMouseLeave={(e) =>
                              (e.target.style.backgroundColor = '#c991fb')
                          }
                      >
                    <span style={{margin: '0 10px'}}>
                      {formattedStartDate}
                    </span>
                      </div>
                    </div>
                );
              })}
        </div>
      </Col>
      {selectedFile && (
          <Col sm={12} md={8} style={{flex: 2, padding: '0 20px'}}>
            <div
                style={{
                  textAlign: 'center',
                  margin: '10px 0',
                  backgroundColor: '#f8f8f8',
                  padding: '10px',
                  borderRadius: '5px',
                }}
            >
              <h2 style={{margin: '0', fontSize: '1.5rem', color: '#333'}}>
                Previews
              </h2>
              <p
                  style={{
                    margin: '5px 0',
                    color: '#333',
                    textAlign: 'center',
                  }}
              >
                {selectedFile
                    ? `${selectedFile.substring(4, 6)}/${selectedFile.substring(
                        6,
                        8
                    )}/${selectedFile.substring(0, 4)}`
                    : 'Select a file to view the date'}
              </p>
            </div>

            <div
                style={{
              border: '1px solid #ddd',
              borderRadius: '5px',
              marginBottom: '10px',
            }}
          >
            <PdfViewer file={`./${selectedFile}`} />
          </div>
          <div style={{ marginTop: '10px', textAlign: 'center' }}>
            <button
              onClick={() => {
                const link = document.createElement('a');
                link.href = `./${selectedFile}`;
                link.download = selectedFile;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}
              style={{
                padding: '5px 16px',
                backgroundColor: '#feed30',
                color: 'black',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                margin: '10px',
                fontSize: '0.9rem',
                width: '200px',
              }}
            >
              Download
            </button>
          </div>
        </Col>
      )}
    </Row>
  );
};

export default PdfFileList;
