import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from './../assets/logoDash.png';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import { Col, Row } from 'react-bootstrap';
import ReportOne from './../assets/report1.png';
import ReportTwo from './../assets/report2.png';
import ReportThree from './../assets/report3.png';
import SignOut from './../assets/signout.png';

import './../styling/dashboard.css';
import PdfFileList from './PdfFileList';
import HistoricalPDF from './HistoricalPDF';
import MemoPDF from './MemoPDF';

const Dashboard = ({ setShowNavbar, setShowFooter, setIsLoggedIn }) => {
  const [selectedTab, setSelectedTab] = useState('reports');
  const navigate = useNavigate();
  const username = localStorage.getItem('username');

  useEffect(() => {
    setShowNavbar(false);
    setShowFooter(false);

    return () => {
      setShowNavbar(true);
      setShowFooter(true);
    };
  }, [setShowNavbar, setShowFooter]);

  const handleSignOut = () => {
    localStorage.removeItem('username');
    setIsLoggedIn(false);
    navigate('/');
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const renderContent = () => {
    // eslint-disable-next-line default-case
    switch (selectedTab) {
      case 'reports':
        return (
          <div className="right-sidebar">
            <div className="content-header">
              <h2>Dashboard</h2>
              <span>Welcome, {username}</span>
            </div>
            <PdfFileList />
          </div>
        );

      case 'investmentMemo':
        return (
          <div>
            <div className="content-header">
              <h1>Dashboard</h1>
              <span>Welcome, {username}</span>
            </div>
            <MemoPDF />
          </div>
        );
      case 'historical':
        return (
          <div>
            <div className="content-header">
              <h2>Dashboard</h2>
              <span>Welcome, {username}</span>
            </div>
            <HistoricalPDF />
          </div>
        );
    }
  };

  return (
    <Row className="dashborad">
      {/* <div className="dashboard-container"> */}
      <Col md={2} className="sidebar">
        <Navbar.Brand href="/" className="brand-container">
          <Image
            src={Logo}
            alt="Logo"
            className="logo-image"
            style={{ width: '100%', height: 'auto' }}
          />
        </Navbar.Brand>

        <ul className="side-report d-flex flex-column">
          <div>
            <li className="report1 d-flex flex-row ">
              <button
                className={`btn ${
                  selectedTab === 'reports' ? 'active' : ''
                } d-flex flex-row align-items-center`}
                onClick={() => handleTabClick('reports')}
              >
                <Image src={ReportOne} className="icon" />
                <p>Reports</p>
              </button>
            </li>
          </div>

          <div>
            <li className="report1 d-flex flex-row">
              <button
                className={
                  selectedTab === 'investmentMemo' ? 'active btn' : 'btn'
                }
                onClick={() => handleTabClick('investmentMemo')}
              >
                <Image src={ReportTwo} className="icon " />
                <p>Investment Memo</p>
              </button>
            </li>
          </div>

          <li>
            <button
              className={selectedTab === 'historical' ? 'active btn' : 'btn'}
              onClick={() => handleTabClick('historical')}
            >
              <Image src={ReportThree} className="icon " />
              <p>Historical</p>
            </button>
          </li>
          <li>
            <button className="btn signout" onClick={handleSignOut}>
              <Image src={SignOut} className="icon " />
              <p>Sign out</p>
            </button>
          </li>
        </ul>
      </Col>
      <Col md={10}>
        <div className="content-area">{renderContent()}</div>
      </Col>
    </Row>
  );
};

export default Dashboard;
