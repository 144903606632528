import React from 'react';
import LoginContainer from '../components/LoginContainer';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Login = ({ setIsLoggedIn }) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('username')) {
            navigate('/dashboard');
        }
    }, [navigate]);

    return (
        <div>
            <LoginContainer setIsLoggedIn={setIsLoggedIn} />
        </div>
    );
};

export default Login;
