import React, { useState, useEffect } from 'react';
import PdfViewer from './PdfViewer';
import { Col, Row } from 'react-bootstrap';

const MemoPDF = () => {
  const [selectedFile, setSelectedFile] = useState('FRSUSDebt.pdf'); // Default to the first file
  const files = ['FRSUSDebt.pdf'];

  useEffect(() => {
    setSelectedFile(files[0]);
  }, [files]);

  return (
    <Row>
      <Col>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <PdfViewer file={`./${selectedFile}`} />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '10px',
          }}
        >
          <button
            onClick={() => {
              const link = document.createElement('a');
              link.href = `./${selectedFile}`;
              link.download = selectedFile;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}
            style={{
              padding: '5px 16px',
              backgroundColor: '#feed30',
              color: 'black',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              fontSize: '0.9rem',
              width: '200px',
            }}
          >
            Download
          </button>
        </div>
      </Col>
    </Row>
  );
};
export default MemoPDF;
