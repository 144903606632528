import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image';
import Logo from './../assets/logo.png';

import './../styling/navbar.css';

const MyNavbar = () => {
  return (
    <Navbar expand="lg" className="navbar px-4 py-5">
      <Container fluid>
        <div className="d-flex align-items-center">
          <Navbar.Brand href="/">
            <Image
              src={Logo}
              alt="Logo"
              style={{ width: '30%', height: 'auto' }}
              className="logo-image "
            />
          </Navbar.Brand>

          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            style={{
              color: 'white',
              backgroundColor: 'grey',
              fontSize: '1.2rem',
            }}
          />
        </div>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            className="ms-auto ml-auto d-flex flex-row  justify-content-between mr-4"
            style={{ minWidth: '60%' }}
          >
            <NavDropdown
              title="Investment"
              id="basic-nav-dropdown"
              style={{ fontSize: '1.2rem' }} // Adjust font size for responsiveness
              className="navbar-link"
            >
              <NavDropdown.Item href="/">Investment</NavDropdown.Item>
              <NavDropdown.Item href="/">Investment</NavDropdown.Item>
              <NavDropdown.Item href="/">Investment</NavDropdown.Item>
            </NavDropdown>

            <Nav.Link
              href="/team"
              style={{ fontSize: '1.2rem', color: 'white' }}
              className="navbar-link"
            >
              Our Team
            </Nav.Link>
            <Nav.Link
              href="/career"
              style={{ fontSize: '1.2rem', color: 'white' }}
              className="navbar-link"
            >
              Careers
            </Nav.Link>
            <Nav.Link
              href="/contact"
              style={{ fontSize: '1.2rem', color: 'white' }}
              className="navbar-link"
            >
              Contact Us
            </Nav.Link>
            <Nav.Link
              href="/login"
              style={{ fontSize: '1.2rem', color: 'white' }}
              className="navbar-link"
            >
              Investor Login
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MyNavbar;
