import React, { useState, useEffect } from 'react';
import './../styling/loginContainer.css';
import { Link, useNavigate } from 'react-router-dom';

const LoginContainer = ({ setIsLoggedIn }) => {
  const [emailOrUsername, setEmailOrUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const storedEmailOrUsername = localStorage.getItem(
        'rememberedEmailOrUsername'
    );
    const storedPassword = localStorage.getItem('rememberedPassword');
    const storedRememberMe = localStorage.getItem('rememberedRememberMe');

    if (storedEmailOrUsername && storedPassword && storedRememberMe) {
      setEmailOrUsername(storedEmailOrUsername);
      setPassword(storedPassword);
      setRememberMe(true);
    }
  }, []);

  const handleLogin = () => {
    const credentials = {
      EmailOrUsername: emailOrUsername,
      Password: password,
    };

    fetch('/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(credentials),
    })
        .then((response) => response.json())
        .then((data) => {
          if (data.message === 'Login successful') {
            localStorage.setItem('username', data.username);
            setIsLoggedIn(true);
            if (rememberMe) {
              localStorage.setItem('rememberedEmailOrUsername', emailOrUsername);
              localStorage.setItem('rememberedPassword', password);
              localStorage.setItem('rememberedRememberMe', 'true');
            } else {
              localStorage.removeItem('rememberedEmailOrUsername');
              localStorage.removeItem('rememberedPassword');
              localStorage.removeItem('rememberedRememberMe');
            }
            navigate('/dashboard');
          } else {
            alert('Login failed: Incorrect email/username or password');
            setEmailOrUsername('');
            setPassword('');
            setRememberMe(false);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
  };

  return (
      <div className="login-box">
        <div className="login-container">
          <p className="login-header">Investor Login</p>
          <form>
            <div className="form-group">
              <input
                  type="text"
                  placeholder="Email or Username"
                  value={emailOrUsername}
                  onChange={(e) => setEmailOrUsername(e.target.value)}
                  className="white-text-input"
              />
            </div>
            <div className="form-group">
              <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="white-text-input"
              />
            </div>
            <div className="add-option form-group">
              <div>
                <label>
                  <input
                      type="checkbox"
                      id="checkbox"
                      checked={rememberMe}
                      onChange={() => setRememberMe(!rememberMe)}
                      className="white-text-input"
                  />
                  Remember me
                </label>
              </div>
              <div>
                <div className="password-reset-link">
                  <Link to="/reset-password">Forgot your password?</Link>
                </div>
              </div>
            </div>
            <div className="form-group">
              <button type="button" onClick={handleLogin}>
                Login
              </button>
            </div>
          </form>

          <p className="login-text">
            You are logging in to our secure site. Access is by invitation only
            and requires a Username and Password. If you experience any problems
            logging in, please contact us at steve@laplaceinvestment.com
          </p>
        </div>
      </div>
  );
};

export default LoginContainer;
