import React, { useState } from 'react';
import PdfViewer from './PdfViewer';
import { Col, Row } from 'react-bootstrap';

const HistoricalPDF = () => {
    const [selectedYear, setSelectedYear] = useState('2023');
    const [selectedMonth, setSelectedMonth] = useState('02');
    const allFiles = [
        '202302.pdf',
        '202303.pdf',
        '202304.pdf',
        '202305.pdf'
    ];

    const filteredFiles = allFiles.filter(file =>
        file.startsWith(`${selectedYear}${selectedMonth}`)
    );

    const [selectedFile, setSelectedFile] = useState(filteredFiles.length > 0 ? filteredFiles[0] : null);

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
        const newFilteredFiles = allFiles.filter(file =>
            file.startsWith(`${e.target.value}${selectedMonth}`)
        );
        setSelectedFile(newFilteredFiles.length > 0 ? newFilteredFiles[0] : null);
    };

    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value);
        const newFilteredFiles = allFiles.filter(file =>
            file.startsWith(`${selectedYear}${e.target.value}`)
        );
        setSelectedFile(newFilteredFiles.length > 0 ? newFilteredFiles[0] : null);
    };

    const handleDownload = () => {
        if (!selectedFile) return;

        const link = document.createElement('a');
        link.href = `./${selectedFile}`;
        link.download = selectedFile;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div>
            <Row>
                <Col md={4} style={{flex: 1, padding: '10px'}}>
                    <div className="file-selector" style={{
                        backgroundColor: 'white',
                        padding: '10px',
                        borderRadius: '5px',
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <div style={{margin: '0 10px'}}> {}
                            <select value={selectedYear} onChange={handleYearChange}
                                    style={{
                                        padding: '5px',
                                        backgroundColor: 'lightgrey',
                                        borderRadius: '5px',
                                        minWidth: '120px'
                                    }}>
                                <option value="2023" style={{backgroundColor: '#e0e0e0'}}>2023</option>
                            </select>
                        </div>
                        <div style={{margin: '0 10px'}}> {}
                            <select value={selectedMonth} onChange={handleMonthChange}
                                    style={{
                                        padding: '5px',
                                        backgroundColor: 'lightgrey',
                                        borderRadius: '5px',
                                        minWidth: '120px'
                                    }}>
                                <option value="02" style={{backgroundColor: '#e0e0e0'}}>February</option>
                                <option value="03" style={{backgroundColor: '#e0e0e0'}}>March</option>
                                <option value="04" style={{backgroundColor: '#e0e0e0'}}>April</option>
                                <option value="05" style={{backgroundColor: '#e0e0e0'}}>May</option>
                            </select>
                        </div>
                    </div>

                    {selectedFile && (
                        <div
                            style={{marginBottom: '10px', cursor: 'pointer', margin: '20px'}}
                            onClick={() => setSelectedFile(selectedFile)}
                        >
                            <div
                                style={{
                                    height: '60px',
                                    backgroundColor: '#c991fb',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: 'white',
                                    borderRadius: '5px',
                                    transition: 'background-color 0.3s',
                                }}
                            >
                                <span style={{margin: '0 10px'}}>
                                    {selectedFile.substring(0, 4)}-{selectedFile.substring(4, 6)}
                                </span>
                            </div>
                        </div>
                    )}
                </Col>
                <Col md={8}>
                    {selectedFile && <PdfViewer file={selectedFile}/>}
                    <div style={{marginTop: '10px', textAlign: 'center'}}>
                        <button
                            onClick={handleDownload}
                            style={{
                                padding: '5px 16px',
                                backgroundColor: '#feed30',
                                color: 'black',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                margin: '10px',
                                fontSize: '0.9rem',
                                width: '200px',
                            }}
                        >
                            Download
                        </button>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default HistoricalPDF;



