import React, { useState } from 'react';
import './../styling/career.css';
import { Col, Row } from 'react-bootstrap';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
function Career() {
  const [isDragging, setIsDragging] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    resume: null,
    linkedin: '',
    website: '',
  });

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files.length) {
      setFormData((prevData) => ({ ...prevData, resume: files[0] }));
    }
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    const file = event.target.files[0];
    setFormData({
      ...formData,
      resume: file,
      resumeName: file ? file.name : ''
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!formData.resume) {
      alert('Please upload a resume before submitting.');
      return;
    }
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('firstName', formData.firstName);
      formDataToSend.append('lastName', formData.lastName);
      formDataToSend.append('email', formData.email);
      formDataToSend.append('phone', formData.phone || '');
      formDataToSend.append('linkedin', formData.linkedin || '');
      formDataToSend.append('website', formData.website || '');
      formDataToSend.append('resume', formData.resume);

      const response = await fetch('/api/submit', {
        method: 'POST',
        body: formDataToSend,
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setIsSubmitted(true);
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          resume: null,
          linkedin: '',
          website: '',
          resumeName: '',
        });
      } else {
        console.error('Error while submitting application');
      }
    } catch (error) {
      console.error('Error while submitting application:', error);
    }
  };

  return (
    <Row>
      <Col lg={1}></Col>
      <Col lg={4}>
        <div className="left mt-5 mb-5">
          <h2>Openings</h2>
          <h3>Engineering</h3>

          <div className="job-button">
            <button className="mb-3">
              Software Engineer 2 - Design System
              <br />
              Full Time | Remote | SF, US
            </button>
            {/* <button className="mb-3">
              Software Engineer Back End
              <br />
              Full Time | Remote | SF, US
            </button> */}
          </div>
        </div>
      </Col>

      <Col lg={5} style={{ backgroundColor: '' }}>
        <div className="right d-flex flex-column mt-5 mb-5 ml-4">
          <div className="title">
            <h2>Software Engineer 2 - Design System</h2>
          </div>
          <div className="location">
            <p className="location">Full Time Remote SF | US</p>
          </div>

          <button className="apply-button" type="button">
            Apply Now
          </button>
          <br className="br-line" />
          <hr />

          <div className="mt-2">
            <h3>About The Job</h3>
            <p className="location">
              We’re looking for an engineer to use a data-driven approach to
              help define the future of web browsing! Come help transform
              Laplace website and directly affect how people live, communicate,
              and connect. Together, we’ll build on the industry-leading WebKit
              engine and Laplace’s frameworks to deliver a fluid browsing
              experience and powerful, easy-to-use features.
            </p>

            <h3 className="mt-5">We're looking for someone who has:</h3>
            <ul className="job-desire ml-4">
              <li>
                A strong technical background including > 6 years building
                applications using JavaScript / TypeScript.
              </li>
              <li>
                Experience working across the stack from browser tweaks to get
                the rendering just right to storing new data in the database and
                everywhere in between.
              </li>
              <li>
                A desire to get better everyday. Titan’s best days are still to
                come and so are yours.
              </li>
              <li>
                An ability to work in a fast-paced environment with subject
                matter experts from across the company.
              </li>
              <li>
                A strong sense of responsibility and integrity. We’re a
                fiduciary of our clients and we always act with their best
                interests at heart.
              </li>
              <li>
                The ability to lead and mentor other members of the team on best
                practices in architecture, security, and design.
              </li>
            </ul>
          </div>

          <div className="apply-form d-flex flex-column">

            {!isSubmitted ? (
                <>
                  <h3 className="ml-4 mt-4">Apply For This Position</h3>
                  <div className="form ml-4 mr-4 mb-4 mt-4">
                    <form onSubmit={handleSubmit}>
                      <div className="d-flex flex-row label-input-container">
                        <label htmlFor="firstName">First Name*</label>
                        <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleInputChange}
                            required
                        />
                      </div>

                      <br/>

                      <div className="d-flex flex-row label-input-container">
                        <label htmlFor="lastName">Last Name*</label>
                        <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleInputChange}
                            required
                        />
                      </div>
                      <br/>

                      <div className="d-flex flex-row label-input-container">
                        <label htmlFor="email">Email*</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                      </div>
                      <br/>

                      <div className="d-flex flex-row label-input-container">
                        <label htmlFor="phone">Phone*</label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            required
                        />
                      </div>
                      <br/>

                      <label htmlFor="resume" className="file-upload-label">
                        Resume/CV*
                        <div
                            className="input-container mb-5 d-flex flex-column justify-content-center align-items-center">
                          <i className="upload-icon mt-5 mb-3">
                            <CloudUploadOutlinedIcon style={{fontSize: 50}}/>
                          </i>
                          <p className="text">Drag and Drop here</p>
                          <p className="text">Or</p>
                          <p className="select">Select file</p>
                          <input
                              type="file"
                              id="resume"
                              name="resume"
                              onChange={handleFileChange}
                              accept=".pdf, .doc, .docx"
                              style={{display: 'none'}}
                          />
                          {formData.resumeName && (
                              <div className="resume-name-display">
                                {formData.resumeName}
                              </div>
                          )}
                        </div>
                      </label>
                      <hr/>

                      <div className="d-flex flex-row label-input-container mt-5">
                        <label htmlFor="email">linkedIn*</label>
                        <input
                            type="url"
                            id="linkedin"
                            name="linkedin"
                            value={formData.linkedin}
                            onChange={handleInputChange}
                        />
                      </div>
                      <br/>
                      <div className="d-flex flex-row label-input-container">
                        <label htmlFor="email">Website *</label>
                        <input
                            type="url"
                            id="website"
                            name="website"
                            value={formData.website}
                            onChange={handleInputChange}
                        />
                      </div>
                      <br/>

                      <div className="d-flex flex-row">
                        <button className="submit ml-auto">Submit Application</button>
                      </div>
                    </form>
                  </div>
                </>

            ) : (
                <div className="success-message" style={{ fontSize: '20px' }}>
                  Your application has been successfully sent, and if you are a suitable candidate, we will contact you
                  as soon as possible.
                </div>
            )}
          </div>
        </div>
      </Col>
      <Col lg={1}></Col>
    </Row>
  );
}

export default Career;
