import React, { useState } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import './../styling/home.css';
import HomeButton from './../components/HomeButton';

import section2 from './../assets/section2.png';
import section4 from './../assets/section4.png';

import stock from './../assets/1.png';
import stock2 from './../assets/2.png';
import stock3 from './../assets/3.png';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';

const Home = () => {
  const [activeSection, setActiveSection] = useState('section-1');

  const scrollToSection = (sectionId) => {
    console.log('button clicked');
    setActiveSection(sectionId);
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <Container fluid>
      {/* Section 1 */}
      <Row className="section-1">
        <Col lg={1} sm={1}></Col>
        <Col lg={6} md={6}>
          <div className="container d-flexw  align-self-center">
            <h1 style={{ fontSize: '3.5em', fontWeight: '700', color: '#fff' }}>
              We make educated predictions
            </h1>
            <h4 className="mt-4" style={{ color: '#fff' }}>
              For investors seeking long-term growth
            </h4>
            <div className="buttons-wrapper d-flex flex-row justify-content-between mt-5">
              <HomeButton
                text="About Us"
                icon={faArrowRightLong}
                onClick={() => scrollToSection('section-2')}
              />
              <HomeButton
                text="Our Selections"
                icon={faArrowRightLong}
                onClick={() => scrollToSection('section-3')}
              />
              <HomeButton
                text="Our Strategies"
                icon={faArrowRightLong}
                onClick={() => scrollToSection('section-4')}
              />
            </div>
          </div>
        </Col>
        <Col lg={1}></Col>
      </Row>

      {/* section 2 */}

      <Row className="section-2" id="section-2">
        <Col lg={2} sm={1} md={1}></Col>
        <Col lg={8} sm={10} md={10} className="wrapper2">
          <Image
            src={section2}
            style={{ width: '100%', height: 'auto' }}
          ></Image>
        </Col>
        <Col lg={2} sm={0} md={1}></Col>
      </Row>

      {/* Section 3 */}
      <Row className="section-3 " id="section-3">
        <Container fluid className="section3-container mt-2 mb-5">
          <div className="mt-5 text-center">
            <h2>How do we identify investment opportunities?</h2>
          </div>
          <Row className="words mt-3">
            <Col className="mt-3" lg={1}></Col>
            <Col className="mt-3" lg={3}>
              <div className="d-flex flex-column">
                <img
                  src={stock}
                  alt=""
                  style={{ width: '70px', height: '70px' }}
                />
                <h6>Industry and Stock Selection </h6>

                <p style={{ color: '#fff', fontSize: '1.2rem' }}>
                  The GP does quantitative and qualitative research on different
                  industries to select those that are highly certain to grow in
                  the future. It uses Parallel Filtered Graph, Planar Maximally
                  Filtered Graph, Triangulated Maximally Filtered Graph and
                  other algorithms to do the clustering. This allows the GP to
                  identify industries for the firms without any bias.
                </p>
              </div>
            </Col>
            <Col className="mt-4"></Col>
            <Col className="mt-4" lg={3}>
              <div className="d-flex flex-column">
                <img
                  src={stock2}
                  alt=""
                  style={{ width: '70px', height: '80px' }}
                />

                <h6>Industry and Stock Selection </h6>
                <p style={{ color: '#fff', fontSize: '1.2rem' }}>
                  The GP uses a Multi-Factor Math Model to select firms in great
                  industries which are suitable to invest. The valuation model
                  can help the GP purchase under-valued stocks and sell those
                  that are over-valued, including shorting stocks with
                  manageable risk.
                </p>
              </div>
            </Col>
            <Col className="mt-4"></Col>
            <Col className="mt-4" lg={3}>
              <div className="d-flex flex-column">
                <img
                  src={stock3}
                  alt=""
                  style={{ width: '70px', height: '80px' }}
                />
                <h6>Industry and Stock Selection </h6>
                <p style={{ color: '#fff', fontSize: '1.2rem' }}>
                  The GP monitors macroeconomic indicators and adjusts models
                  based on new data. The GP uses Monte Carlo simulation, Linear
                  Programming and other models in order to seek maximal
                  potential profits with limited risks. The GP will adjust the
                  portfolio depending on political, financial and competition
                  risks.
                </p>
              </div>
            </Col>
            <Col className="mt-4"></Col>
            <Col className="mt-4" lg={1}></Col>
          </Row>
        </Container>
      </Row>

      <Row className="section-4" id="section-4">
        <Col lg={1} md={1}></Col>
        <Col lg={8} md={10} className="wrapper4">
          <Image
            src={section4}
            style={{ width: '100%', height: 'auto' }}
          ></Image>
        </Col>
        <Col lg={1} md={1}></Col>
      </Row>
    </Container>
  );
};

export default Home;
