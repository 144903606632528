import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import './../styling/team.css';

import person1 from './../assets/person1.png';
import person2 from './../assets/person2.png';
import person3 from './../assets/person3.png';

const Team = () => {
  return (
    <div className="team-container">
      <Container fluid>
        <Row className="team justify-content-center align-items-center ">
          <h1 className="text-center mb-5">Meet Our Amazing Team</h1>
        </Row>
        <Row className="team1">
          <Col lg={2}></Col>
          <Col
            lg={8}
            sm={12}
            className="d-flex flex-row   justify-content-around"
          >
            <Col lg={2} sm={3}>
              <div className="d-flex flex-column ">
                <Image
                  className="person1 img-fluid"
                  src={person1}
                  alt="Person 1"
                />
                <div className="member mt-3 mb-2">
                  <h3>Bide Peter Liang </h3>
                  <h4>CEO</h4>
                  <p style={{ color: 'white' }}>
                    As Chief Executive Officer, Bide leads the team in
                    developing advanced investment strategies and keeps close
                    connection with Limited Partners as well as doing marketing.
                  </p>
                </div>
              </div>
            </Col>

            <Col lg={2} sm={3}>
              <div className="d-flex flex-column ">
                <Image
                  className="person2 img-fluid"
                  src={person2}
                  alt="Person 2"
                />
                <div className="member mt-3 mb-2">
                  <h3>Hao Steve Chen</h3>
                  <h4>CIO</h4>
                  <h4> Cheif Economist</h4>
                  <p style={{ color: 'white' }}>
                    As Chief Investment Officer, Hao built and continues
                    developing the multifactor model, performs market research
                    and data analysis, and monitors economy indicators for
                    Laplace Investment Management.
                  </p>
                </div>
              </div>
            </Col>

            <Col lg={2} sm={3}>
              <div className="d-flex flex-column flex-wrap">
                <Image
                  className="person3 img-fluid"
                  src={person3}
                  alt="Person 3"
                />
                <div className="member mt-3 mb-2">
                  <h3>Xuetong Lisa Li</h3>
                  <h4>Chief Futurist</h4>
                  <p style={{ color: 'white' }}>
                    As Chief Futurist, Xuetong drives long-term forecasts across
                    technologies and economies. Xuetong helps Laplace dimension
                    the impact of disruptive innovation as it transforms the
                    global economy. She does research in Arts, Sociology,
                    Psychology and Behavioral economics.
                  </p>
                </div>
              </div>
            </Col>
          </Col>
          <Col lg={2}></Col>
        </Row>
      </Container>
    </div>
  );
};

export default Team;
