import './App.css';
import MyNavbar from './components/MyNavbar';
import Home from './pages/Home';
import ContactUs from './pages/ContactUs';
import Login from './pages/Login';
import Team from './pages/Team';
import Footer from './components/Footer';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import ResetPassword from './pages/ResetPassword';
import Career from './pages/Career';
import { useState, useEffect } from 'react';
import Dashboard from './pages/Dashboard';

function App() {
    const [showNavbar, setShowNavbar] = useState(true);
    const [showFooter, setShowFooter] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const username = localStorage.getItem('username');
        setIsLoggedIn(!!username);
    }, []);

    const ProtectedRoute = ({ children }) => {
        return isLoggedIn ? children : <Navigate to="/login" />;
    };

    return (
        <>
            <Router>
                {showNavbar && <MyNavbar />}
                <Routes>
                    <Route path="/contact" element={<ContactUs />} />
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/career" element={<Career />} />
                    <Route path="/team" element={<Team />} />
                    <Route
                        path="/dashboard"
                        element={
                            <ProtectedRoute>
                                <Dashboard setShowNavbar={setShowNavbar} setShowFooter={setShowFooter} setIsLoggedIn={setIsLoggedIn} />
                            </ProtectedRoute>
                        }
                    />
                </Routes>
                {showFooter && <Footer />}
            </Router>
        </>
    );
}

export default App;
