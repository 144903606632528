import React, { useState } from 'react';

const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [resetSuccess, setResetSuccess] = useState(false);
    const [userNotFound, setUserNotFound] = useState(false);

    const handleResetPassword = () => {
        if (newPassword === confirmPassword) {
            fetch('/api/reset-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ Email: email, NewPassword: newPassword }),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.message === 'Password reset successful' && data.passwordUpdated) {
                        setResetSuccess(true);
                    } else if (data.message === 'User not found') {
                        setUserNotFound(true);
                    } else {
                        alert('Invalid user or password. Please check your credentials.');
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                    alert('An error occurred. Please try again later.');
                });
        } else {
            alert('Passwords do not match. Please try again.');
        }
    };

    const redirectToLogin = () => {
        window.location.href = '/login';
    };

    return (
        <div className="login-box">
            <div className="login-container">
                <p className="login-header">Reset Password</p>
                {resetSuccess ? (
                    <div className="white-text-input">
                        Password reset successful. You can now log in with your new password.
                        {redirectToLogin()}
                    </div>
                ) : (
                    <>
                        <div className="form-group">
                            <input
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="white-text-input"
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                placeholder="New Password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                className="white-text-input"
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className="white-text-input"
                            />
                        </div>
                        <div className="form-group">
                            <button type="button" onClick={handleResetPassword}>
                                Reset Password
                            </button>
                        </div>
                        {userNotFound && (
                            <div className="white-text-input">
                                User not found . Please enter a valid email address.
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default ResetPassword;

