import React from 'react';
import { Button } from 'react-bootstrap';
import './../styling/button.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HomeButton = ({ text, icon, onClick }) => {
  return (
    <Button
      className="button d-flex align-items-center justify-content-center "
      onClick={onClick}
    >
      <span className="text mr-3"> {text}</span>
      <FontAwesomeIcon className="sicon ml-4" icon={icon} />
    </Button>
  );
};

export default HomeButton;
